<template>
  <BaseModal
    v-if="ativo"
    :telaInteira="false"
    :tituloModal="'a'"
    :classesAdicionaisModal="'rounded'"
    :classesAdicionaisHeader="'d-none'"
    :classesAdicionaisFooter="'d-none'"
    :classesAdicionaisBody="'p-0'"
    id="modalLeitorCodigoBarras"
  >
    <div slot="body" class="bg-dark">
      <div class="h5 text-center text-white">Aponte a camera para o {{ tipoLeitura }} desejado</div>
      <div class>
        <StreamBarcodeReader @decode="retornoCodigoBarras" @loaded="onLoaded" />
      </div>
      <div class="text-center mt-2 pb-2">
        <a id="lmxta-leitor-btn-cancelar" class="btn btn-default" @click="cancelar">CANCELAR</a>
      </div>
    </div>
  </BaseModal>
</template>

<script>
  import { StreamBarcodeReader } from 'vue-barcode-reader';
  import { mapGetters } from 'vuex';
  import { EventBus } from '@/common/eventBus';

  export default {
    name: 'LeitorCodigoBarras',
    components: {
      StreamBarcodeReader,
    },
    props: {
      ativo: {
        type: Boolean,
        default: false,
      },
      tipoLeitura: {
        type: String,
        default: 'código de barras',
      },
    },
    data() {
      return {};
    },
    methods: {
      retornoCodigoBarras(result) {
        this.$emit('input', { codigoBarras: result });
      },
      cancelar() {
        this.$emit('input', { codigoBarras: null });
      },
      onLoaded(result) {
        //console.log('onLoaded', result);
      },
    },
  };
</script>
