<template>
    <input
      type="number"
      v-bind="$attrs"
      v-model="valor"
      @blur="onBlur"
      @keydown="onKeydown"
      :disabled="isDisabled"
      :step="stepInput"
      :min="valorMinimo"
      maxlength="15"
      class="form-control"
    />
</template>

<script>
  export default {
    name: 'InputNumber',
    props: {
      value: {
        type: [Number, String],
        default: 0,
      },
      precisao: {
        type: Number,
        default: 0,
      },
      valorMinimo: {
        type: Number,
        default: 1,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        valor: 0,
      }
    },
    watch: {
      value: {
        handler(newValue) {
          this.valor = newValue;
        },
        immediate: true,
      },
      valor(newValue) {
        this.$emit('input', Number(newValue));
      },
    },
    computed: {
      stepInput() {
				return this.precisao < 1 ? "1" : "0.1"
			},
    },
    methods: {
      onBlur() {
				if (this.valor < this.valorMinimo) 
				  this.valor = this.valorMinimo;
			},
			onKeydown() {
				var self = this;
				var oldVal = self.valor;
				var regex = new RegExp("^^\\d*(\\.\\d{0," + self.precisao + "})?$", 'g');
		
				setTimeout(() => {
					var newVal = self.valor;

					if(!regex.test(newVal)) 
            self.valor = oldVal;
				}, 1);
			},
    },
  };
</script>

<style scoped></style>
